<template>
<div class="flex">
  <div class="box-table">
    <a-table class="table-box" bordered :data-source="dataSource" :columns="columns" :pagination="pagination" :row-selection="{...rowSelection}" @change="change" rowKey="id">
      <template #title>
        <div class="mb-10">全部百科{{total}}</div>
        <div class="top-flex">
          <a-select
            v-model:value="tag_id"
            @change="fetchGetWikiList"
            style="width: 100%;margin-right:10px;"
            placeholder="请选择百科标签"
            :getPopupContainer='(triggerNode) => triggerNode.parentNode'
          >
            <a-select-option :value="item.id" v-for="item in unitList" :key="item.id">{{item.item}}</a-select-option>
          </a-select>
          <a-input-search v-model:value="search" placeholder="请输入百科名称搜索" @search="fetchSearchGetWikiList" allowClear/>
        </div>
      </template>
      <template #actionId="{ record }">
        <a style="cursor: pointer;" @click="goEditor(record.id)">{{record.id}}</a>
      </template>
    </a-table>
  </div>
  <div class="box-table">
    <a-table class="table-box" bordered :data-source="checkDataSourse" :columns="columns_all" rowKey="id" :pagination="false" :customRow="customRow" :scroll="{y: 331}">
      <template #title>
        <div class="table-text">已选百科{{checkDataSourse.length}}</div>
      </template>
      <template #actionId="{ record }">
        <a style="cursor: pointer;" @click="goEditor(record.id)">{{record.id}}</a>
      </template>
       <template #action1="{record}">
          <a-button style="height:20px;" type="link" size="small" class="col-red" @click.prevent="deleteItem(record)"><DeleteOutlined /></a-button>
       </template>
    </a-table>
  </div>
</div>
</template>

<script>
import { getWikiList, getTagList } from '@/api/index'
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { DeleteOutlined } from '@ant-design/icons-vue'
// import draggable from 'vuedraggable'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: { DeleteOutlined },
  props: ['id'],
  setup (props, ctx) {
    const router = useRouter()
    const dataSource = ref([])
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 90,
        slots: { customRender: 'actionId' }
      },
      {
        title: '百科名称',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        ellipsis: true
      },
      {
        title: '百科标签',
        dataIndex: 'tag_str',
        key: 'tag_str',
        width: 100
      },
      {
        title: '百科备注',
        dataIndex: 'remarks',
        key: 'remarks',
        width: 150,
        ellipsis: true
      }
    ]
    const columns_all = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 90,
        slots: { customRender: 'actionId' }
      },
      {
        title: '百科名称',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        ellipsis: true
      },
      {
        title: '百科备注',
        dataIndex: 'remarks',
        key: 'remarks',
        width: 150,
        ellipsis: true
      },
      {
        title: '',
        key: 'action',
        slots: { customRender: 'action1' },
        align: 'right',
        width: 55
      }
    ]
    // 百科类型
    const encType = [{
      id: 0,
      value: '全部'
    }, {
      id: 1,
      value: 'AR资源'
    }, {
      id: 2,
      value: '讲解资源'
    }]
    const paramObj = reactive({
      paginations: {
        page: 1,
        pagesize: 10
      },
      total: 0,
      search: '',
      tag_id: 0,
      type: 0
    })
    let _unit_id = 0
    const unitList = ref([])
    // 获取单元列表
    const fetchUnitList = () => {
      getTagList().then(res => {
        unitList.value = res.data
        unitList.value.unshift({ id: 0, item: '全部' })
        _unit_id = 0
      })
    }
    // 获取课程标签
    const getSelectValue = (value) => {
      paramObj.tag_id = value
    }
    const _checkDataSourse = ref([])
    const rowSelection = reactive({
      columnWidth: 50,
      selectedRowKeys: [], // 指定选中项的 key 数组
      onChange: (selectedRowKeys, selectedRows) => {
        rowSelection.selectedRowKeys = selectedRowKeys
        if (_checkDataSourse.value.length === 0) {
          _checkDataSourse.value = selectedRows
        } else {
          _checkDataSourse.value.push(...selectedRows.filter(item => !_checkDataSourse.value.find(items => items.id === item.id)))
        }
        _checkDataSourse.value = _checkDataSourse.value.filter(item => selectedRowKeys.indexOf(item.id) !== -1)
      }
    })
    const checkDataSourse = computed({
      get: () => {
        const _list = _checkDataSourse.value.map(item => {
          const _item = { ...item }
          return _item
        })
        return _list
      },
      set: (value) => {
        _checkDataSourse.value = value
      }
    })
    // 获取百科资源列表
    const fetchGetWikiList = () => {
      getWikiList(Object.assign(paramObj.paginations, {
        search: paramObj.search,
        tag_id: paramObj.tag_id === '' ? 0 : parseInt(paramObj.tag_id)
      })).then(res => {
        dataSource.value = res.data.map(item => {
          item.key = item.id
          return item
        })
        paramObj.total = res.result
      })
    }
    // 获取分页
    const pagination = computed(() => {
      return {
        current: paramObj.paginations.page,
        pageSize: paramObj.paginations.pagesize,
        total: paramObj.total
      }
    })
    // 分页等改变数据
    const change = (pagination) => {
      paramObj.paginations.page = pagination.current
      fetchGetWikiList()
    }
    // 删除项
    const deleteItem = (item) => {
      const _index = rowSelection.selectedRowKeys.findIndex(items => items === item.id)
      rowSelection.selectedRowKeys.splice(_index, 1)
      _checkDataSourse.value = _checkDataSourse.value.filter(items => items.id !== item.id)
    }
    // 重置数据
    const resetData = () => {
      _checkDataSourse.value = []
      rowSelection.selectedRowKeys = []
    }
    // 初始化数据
    const initData = (obj) => {
      if (obj && obj.ar_ids) {
        rowSelection.selectedRowKeys = obj.ar_ids.split(',').map(item => Number(item))
      }
      if (obj && obj.encyclopedia_list && obj.encyclopedia_list.length) {
        _checkDataSourse.value = obj.encyclopedia_list.map(item => {
          item.key = item.id
          return item
        })
      }
    }
    const fetchDrag = () => {
      ctx.emit('drag', _checkDataSourse.value.map(item => item.id))
    }
    // 拖动排序
    let startIndex = 0
    let endIndex = 0
    let startValue = {}
    let endValue = {}
    const customRow = (record, index) => {
      return {
        // 开始拖拽
        ondragstart: (event) => {
          const ev = event || window.event
          ev.stopPropagation()
          startIndex = index
          startValue = record
          console.log(index, '拖拽开始')
        },
        ondragover: (event) => {
          const ev = event || window.event
          ev.preventDefault()
          console.log('拖拽中')
        },
        ondrop: (event) => {
          const ev = event || window.event
          ev.stopPropagation()
          console.log(index, '目标元素')
          endIndex = index
          endValue = record
          _checkDataSourse.value[startIndex] = endValue
          _checkDataSourse.value[endIndex] = startValue
          fetchDrag()
        },
        onmouseenter: (event) => {
          const ev = event || window.event
          ev.target.draggable = true
        }
      }
    }
    const goEditor = (id) => {
      router.push({ name: 'CreateEnc', params: { id } })
    }
    const fetchSearchGetWikiList = () => {
      paramObj.paginations.page = 1
      fetchGetWikiList()
    }
    onMounted(() => {
      fetchGetWikiList()
      fetchUnitList()
    })
    return {
      goEditor,
      dataSource,
      columns,
      pagination,
      rowSelection,
      change,
      checkDataSourse,
      deleteItem,
      resetData,
      initData,
      ...toRefs(paramObj),
      fetchGetWikiList,
      columns_all,
      unitList,
      getSelectValue,
      customRow,
      encType,
      fetchSearchGetWikiList
    }
  }
})
</script>

<style lang="scss" scoped>
::v-deep .ant-table-thead > tr > th, ::v-deep .ant-table-tbody > tr > td{
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-check{
  flex-wrap: wrap;
  user-select: none;
  max-height: 330px;
  overflow-y: auto;
  .wd-25{
    position: relative;
    cursor: pointer;
    border: 1px solid rgba($color: #000000, $alpha: .2);
    margin-bottom: 5px;
    flex: 0 0 24%;
    padding-left: 5px;
    margin-right: 1%;
    &:hover{
      .delete-icon{
        display: block;
      }
    }
    .delete-icon{
      display: none;
      position: absolute;
      top: -1px;
      right: -1px;
      font-size: 20px;
      color: royalblue;
    }
  }
}
.flex{
  justify-content: space-between;
  .box-table{
    width:48%;
  }
  .col-red{
    color: red;
  }
}
.mb-10{
  margin-bottom: 10px;
}
.table-text{
  height: 64px;
}
::v-deep .ant-table{
  height: 476px;
  .ant-empty-normal {
    margin: 114px 0 !important;
  }
}
.top-flex{
  display: flex;
  align-items: center;
  .mg-r-l{
    margin:0 15px;
  }
}
.table-box{
  table-layout: fixed;
}
</style>
