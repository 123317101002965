
import { computed, defineComponent, ref } from 'vue'
import aliOss from '@/components/ali-oss.vue'
import { PlusOutlined, DeleteOutlined, PictureOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'uploadImage',
  components: {
    aliOss,
    PlusOutlined,
    DeleteOutlined,
    PictureOutlined
  },
  props: {
    id: {
      type: String,
      default: 'file'
    },
    list: {
      type: Array,
      required: true
    },
    upObj: {
      type: Array,
      required: true
    },
    isAuto: {
      type: Boolean,
      default: false
    }, // 是否自动上传，默认不自动上传
    styleType: {
      type: Number,
      default: 1
    }, // 上传样式，默认是框框加加号  2：图片样式
    width: {
      type: Number,
      default: 70
    },
    height: {
      type: Number,
      default: 40
    }
  },
  emits: ['changeFile', 'deleteList'],
  setup (props, ctx) {
    const visible = ref<boolean>(false)
    const videoUrl = ref('')
    const updateFile = ref()
    // 点击上传图标
    const handleClickUp = () => {
      updateFile.value.clearFile()
      const ele = document.getElementById(props.id)
      if (ele) {
        ele.click()
      }
    }
    // 文件选择的回调
    const changeFile = (lists: never[]) => {
      ctx.emit('changeFile', lists)
    }
    // 是否展示上传按钮
    const isShow = computed(() => {
      return props.list.length
    })
    // 删除之前的图片
    const deleteList = () => {
      ctx.emit('deleteList')
    }
    // 上传
    const fetchSend = (callback: () => {}) => {
      updateFile.value.fetchSend(callback)
    }
    // 视频预览
    const lookVideo = (url: string) => {
      visible.value = true
      videoUrl.value = url
    }
    return {
      handleClickUp,
      updateFile,
      changeFile,
      isShow,
      deleteList,
      fetchSend,
      visible,
      videoUrl,
      lookVideo
    }
  }
})
