// 是否为空
export const isValid = (str: string): boolean => {
  return str.trim().length > 0
}
// 是否输入表情
export const isEmoji = (str: string): boolean => {
  const regx = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/ // eslint-disable-line
  if (regx.test(str.trim())) {
    return false
  }
  return true
}
// 是否是手机
export const isPhone = (str: string): boolean => {
  const regx = /^([1][3,4,5,6,7,8,9])\d{9}$/
  if (regx.test(str.trim())) {
    return true
  }
  return false
}
