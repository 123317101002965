export const EXERISE = [{
  id: 1,
  name: '选择题',
  router: 'createChoice'
}, {
  id: 2,
  name: '判断题',
  router: 'createJudge'
}, {
  id: 3,
  name: '连线题',
  router: 'createMatching'
}]

export const EXERISE_TAG = [{
  id: 1,
  name: '教学资源'
}, {
  id: 2,
  name: '数学设备'
}, {
  id: 3,
  name: '红色教育'
}, {
  id: 4,
  name: '医学器材'
}]

export const PROJECT_STATE = {
  新建操作票: 1,
  组长上传操作票: 2,
  负责人审核操作票: 3,
  运行作业: 4,
  等待运行审核: 5,
  共同作业: 6,
  等待验收: 7,
  完成: 8
}

export const TEAM_STATE = {
  0: '',
  1: '待传操作票',
  2: '待审核操作票',
  '-2': '操作票审核未通过',
  4: '运行作业中',
  5: '待验收作业',
  '-5': '作业验收未通过',
  6: '完成'
}

export const MAJOR_STATE = {
  1: '运行专业',
  2: '值班负责',
  3: '检修专业'
}

export const ROLE_STATE = {
  1: '负责人',
  2: '班长',
  3: '组员'
}

export const TYPE = [{
  type_id: 1,
  type_name: '教学资源'
}, {
  type_id: 2,
  type_name: '模拟资源'
}]
