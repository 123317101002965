
import { computed, defineComponent, onMounted, reactive, ref, UnwrapRef } from 'vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import uploadImage from './componets/upload-image.vue'
import { SEND_TYPE, OSSURL } from '@/hooks/ali-oss'
import { TYPE } from '@/hooks/config'
import { checkForm } from './hooks/create-hooks'
import { createCourse, getUnitList, getCourseDes, editCourse } from '@/api/index'
import { message } from 'ant-design-vue'
import { filterKeys } from '@/hooks/common-hooks'
import sourseList from './componets/sourseList.vue'
import { useRouter } from 'vue-router'
interface FormProps {
  name: string;
  remarks: string;
  icon_path: string;
  category_id: number | null;
  image_target_path: string;
  ar_ids: number[] | null;
  type_id: number;
}
export interface FileProps {
  file?: object;
  file_name?: string;
  file_path?: string;
  file_size?: string;
  file_type?: string;
  url?: string;
  [key: string]: any;
}
export default defineComponent({
  components: { uploadImage, sourseList },
  name: 'CreateCourse',
  props: ['id'], // 课程id
  setup (props, context) {
    const router = useRouter()
    const cId = computed(() => { return props.id })
    const sourseList = ref()
    const listIcon: FileProps = ref([])
    const listImageTarget: FileProps = ref([])
    const upObj = ref([SEND_TYPE[1]])
    const changeFileIcon = (lists: []) => {
      listIcon.value = lists
    }
    const changeFileImageTarget = (lists: []) => {
      listImageTarget.value = lists
    }
    const resetListIcon = () => {
      listIcon.value.pop()
    }
    const resetImageTarget = () => {
      listImageTarget.value.pop()
    }
    let _unit_id = 0
    const formState: UnwrapRef<FormProps> = reactive({
      name: '',
      remarks: '',
      icon_path: '',
      category_id: null,
      image_target_path: '',
      ar_ids: null,
      type_id: 1
    })
    const {
      validatename,
      validatRemark
    } = checkForm()
    // 表单验证
    const rules = {
      name: [{ required: true, trigger: 'blur', validator: validatename, whitespace: true }],
      remarks: [{ required: true, trigger: 'blur', message: '请输入备注', whitespace: true }],
      category_id: [{ type: 'number', required: true, trigger: 'blur', message: '请选择课程标签' }],
      type_id: [{ type: 'number', required: true, trigger: 'blur', message: '请选择类型' }]
    }
    const unitList = ref([])
    // 获取单元列表
    const fetchUnitList = () => {
      getUnitList().then(res => {
        unitList.value = res.data
        _unit_id = formState.category_id = res.data[0].id
      })
    }
    // 获取课程标签
    const getSelectValue = (value: number) => {
      formState.category_id = value
    }
    const getSelectTypeValue = (value: number) => {
      formState.type_id = value
    }
    const formRef = ref()
    const iconUrl = computed(() => {
      if (listIcon.value.length === 0) {
        return ''
      } else {
        return (listIcon.value[0] as FileProps).file_path
      }
    })
    const imageTarget = computed(() => {
      if (listImageTarget.value.length === 0) {
        return ''
      } else {
        return (listImageTarget.value[0] as FileProps).file_path
      }
    })
    const fetchCreateCourse = () => {
      createCourse(Object.assign(formState, {
        icon_path: iconUrl.value,
        image_target_path: imageTarget.value,
        ar_ids: sourseList.value.checkDataSourse.map((item: { id: number }) => item.id)
      })).then(() => {
        message.success('创建课程成功')
        context.emit('changeIndex', 0)
        formRef.value.resetFields()
        sourseList.value.resetData()
        listIcon.value.pop()
        listImageTarget.value.pop()
        formState.category_id = _unit_id
      })
    }
    const upload1 = ref()
    const upload2 = ref()
    // 创建课程
    const onSubmit = (callback: () => {}) => {
      formRef.value.validate().then(() => {
        // 创建
        if ((iconUrl.value === '' || iconUrl.value === undefined) && (imageTarget.value === '' || imageTarget.value === undefined)) {
          callback()
        } else {
          if ((iconUrl.value && listIcon.value.length && Object.prototype.hasOwnProperty.call(listIcon.value[0], 'file')) && imageTarget.value && listImageTarget.value.length && Object.prototype.hasOwnProperty.call(listImageTarget.value[0], 'file')) {
            upload1.value.fetchSend()
            // upload2.value.fetchSend(fetchCreateCourse)
            upload2.value.fetchSend(callback)
          } else {
            if (iconUrl.value && listIcon.value.length && Object.prototype.hasOwnProperty.call(listIcon.value[0], 'file')) {
              upload1.value.fetchSend(callback)
            } else if (imageTarget.value && listImageTarget.value.length && Object.prototype.hasOwnProperty.call(listImageTarget.value[0], 'file')) {
              upload2.value.fetchSend(callback)
            } else {
              callback()
            }
          }
        }
      }).catch((error: ValidateErrorEntity<FormProps>) => {
        console.log('error', error)
      })
    }
    // 查询课程详情
    const fetchCourseDes = () => {
      getCourseDes({
        id: props.id
      }).then(res => {
        filterKeys(formState, res.data)
        const _item1 = res.data.icon_path ? [{
          url: OSSURL + '/' + res.data.icon_path,
          file_type: 'p1',
          file_path: res.data.icon_path
        }] : []
        const _item2 = res.data.image_target_path ? [{
          url: OSSURL + '/' + res.data.image_target_path,
          file_type: 'p1',
          file_path: res.data.image_target_path
        }] : []
        listIcon.value = _item1
        listImageTarget.value = _item2
        sourseList.value.initData(res.data)
      })
    }
    // 编辑课程
    const fetchEditCourse = () => {
      // ? iconUrl.value : 'image/default_course_icon.png'
      editCourse(Object.assign(formState, {
        id: Number(props.id),
        icon_path: iconUrl.value,
        image_target_path: imageTarget.value,
        ar_ids: sourseList.value.checkDataSourse.map((item: { id: number }) => item.id)
      })).then(() => {
        message.success('编辑成功')
        router.push({ name: 'CourseManger' })
      })
    }
    onMounted(() => {
      fetchUnitList()
      if (props.id) {
        fetchCourseDes()
      }
    })
    return {
      cId,
      listIcon,
      upObj,
      changeFileIcon,
      resetListIcon,
      listImageTarget,
      changeFileImageTarget,
      resetImageTarget,
      formState,
      getSelectValue,
      rules,
      onSubmit,
      formRef,
      upload1,
      upload2,
      unitList,
      sourseList,
      fetchCreateCourse,
      fetchEditCourse,
      TYPE,
      getSelectTypeValue
    }
  }
})
