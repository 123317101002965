import { isValid, isEmoji } from '@/hooks/validate-hooks'
import { RuleObject } from 'ant-design-vue/es/form/interface'

const validatename = async (rule: RuleObject, value: string) => {
  if (!isValid(value)) {
    return Promise.reject(new Error('请输入课程名称'))
  } else if (!isEmoji(value)) {
    return Promise.reject(new Error('包含非法字符'))
  }
  return Promise.resolve()
}
const validatRemark = async (rule: RuleObject, value: string) => {
  if (!isEmoji(value)) {
    return Promise.reject(new Error('包含非法字符'))
  }
  return Promise.resolve()
}

export const checkForm = () => {
  return {
    validatename,
    validatRemark
  }
}
